import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVueArsenic from 'bootstrap-vue-arsenic'
import VueResource from 'vue-resource';
import $ from 'jquery';
import 'bootstrap';

Vue.use( VueResource )
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(BootstrapVueArsenic)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
//   if(to.meta.content){
//     let head = document.getElementsByTagName('head');
//     let meta = document.createElement('meta');
//     meta.content = to.meta.content;
//     head[0].appendChild(meta)
//   }
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
// 	  console.log(to.meta.title);
//     document.title = to.meta.title;
//   }
  next()
});

Vue.prototype.getToken = function(){
	let cookieStr = document.cookie;
	// console.log(cookieStr);
	let cookies = cookieStr.split(';');
	// console.log(cookies.length);
	for (let i=0;i<cookies.length; i++){
		let kvStr = cookies[i];
		// console.log(kvStr)
		let kv = kvStr.split('=');
		if (kv[0] == 'userinfo') {
			// console.log(kv[0] );
			let value = kv[1];
			// console.log(value);
			return JSON.parse( decodeURIComponent(value) );
		}
	}
	return null;
}
