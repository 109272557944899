import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
	  path: "/login",
	  name: "login",
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  meta: {
	      title: '登录页'
	  },
	  component: () =>
	    import("../views/login.vue")
	},
	{
	  path: "/",
	  name: "admin",
	  meta: {
	      title: '管理后台'
	  },
	  component: () =>
	    import("../views/admin.vue")
	},
	{
		path: "/about",
		name: "about",
		meta: {
			title: '管理后台'
		},
		component: () =>
			import("../views/About.vue")
	}
];

const router = new VueRouter({
  routes
});

export default router;
